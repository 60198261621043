h1 {
  font-family: "Nunito Sans",sans-serif;
  font-weight: 500;
  color: #003349;
  display: inline;
  white-space: nowrap;
  font-size: 10px;
}
h2 {
  font-family: "Nunito Sans",sans-serif;
  white-space: nowrap;
  padding-top: 30px;
}

.App {
  font-family: "Nunito Sans",sans-serif;
  margin: 0;
  padding: 30px;
  font-family: 'Helvetica Neue';
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5;
  margin-left:auto;
  margin-right:auto;
  width: 800px;
}

.App-logo {
  height: 50px;
  float: left;
  padding-right:30px;
}

.App-header {
  /*
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;*/
}

.Upload-form {
  float: left;
  clear: both;
  width: 100%;
  background-color: #CCC;
  padding: 20px;
  margin: 30px 0;
}

.code-block {
  color: #c7254e;
  font-size: 11px;
  line-height: 1;
}

.title {
  font-family: "Nunito Sans",sans-serif;
  font-weight: 500;
  color: #003349;
  display: inline;
  white-space: nowrap;
  font-size: 30px;
}

.form-spinner {
  animation: upload-buffer-spin infinite 4s linear;
  margin: 5px;
}

.status {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
}

table.dataTable {
  border-collapse: collapse !important;
}

table#errors {
  float: left;
  clear: both;
  width: 100%;
  margin: 25px 0;
}

table#errors tr {
  border-bottom: 1px solid #CCC !important;
  border-collapse: collapse !important;
}

table#errors td {
  padding: 5px;
  width: 25%;
}

table#errors td.message {
  color: red;
}

@keyframes upload-buffer-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}